/** colors **/
$primary-color: #666;
$secondary-color: #009FE3;
$third-color: #0E71B8;

/** fonts **/
$font-family: 'Open Sans', arial, verdana, sans-serif;
$font-color: #636363;
$font-size: 13px;
$line-height: 1.4em;

/** grid **/
$column-content-width: 60px;
$gutter-width: 20px;
$breakpoint-offset: 17px;

$columns-xl: 15;
$columns-lg: 12;
$columns-md: 9;
$columns-sm: 6;
$columns-xs: 4;

/** DO NOT CHANGE ANYTHING BELOW HERE
	UNLESS YOU KNOW WHAT YOU ARE DOING **/

$column-width: ($column-content-width + $gutter-width);

$container-width-xs: ($columns-xs * $column-width) - $gutter-width;
$container-width-sm: ($columns-sm * $column-width) - $gutter-width;
$container-width-md: ($columns-md * $column-width) - $gutter-width;
$container-width-lg: ($columns-lg * $column-width) - $gutter-width;
$container-width-xl: ($columns-xl * $column-width) - $gutter-width;

$min-width-xs: ($columns-xs * $column-width) + $breakpoint-offset;
$min-width-sm: ($columns-sm * $column-width) + $breakpoint-offset;
$min-width-md: ($columns-md * $column-width) + $breakpoint-offset;
$min-width-lg: ($columns-lg * $column-width) + $breakpoint-offset;
$min-width-xl: ($columns-xl * $column-width) + $breakpoint-offset;

$col-width-1: $column-width;
$col-width-2: $column-width * 2;
$col-width-3: $column-width * 3;
$col-width-4: $column-width * 4;
$col-width-5: $column-width * 5;
$col-width-6: $column-width * 6;
$col-width-7: $column-width * 7;
$col-width-8: $column-width * 8;
$col-width-9: $column-width * 9;
$col-width-10: $column-width * 10;
$col-width-11: $column-width * 11;
$col-width-12: $column-width * 12;
$col-width-13: $column-width * 13;
$col-width-14: $column-width * 14;
$col-width-15: $column-width * 15;
$col-width-16: $column-width * 16;
$col-width-17: $column-width * 17;
$col-width-18: $column-width * 18;
$col-width-19: $column-width * 19;
$col-width-20: $column-width * 20;