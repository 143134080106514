@import 'default.scss';
@import 'fonts.scss';
.default-font-family {
	font-family: $font-family;
}
.default-font {
	@extend .default-font-family;
	font-size: $font-size;
	color: $font-color;
	line-height: $line-height;
}
.special-font-family-1 {
	/*font-family: 'Enter special font here';*/
}
.special-font-1 {
	/*
	@extend .special-font-family-1;
	font-size: $font-size;
	color: $font-color;
	line-height: $line-height;
	*/
}
body {
	background-color: #FFFFFF;
	padding-right: 1px;
}
.ui-widget,
body,
input,
select,
textarea {
	@extend .default-font;
}
a {
	color: $secondary-color;
	text-decoration: none;
	-webkit-transition: color ease-in-out 0.15s, text-decoration ease-in-out 0.15s, background-color ease-in-out 0.15s;
	-o-transition: color ease-in-out 0.15s, text-decoration ease-in-out 0.15s, background-color ease-in-out 0.15s;
	transition: color ease-in-out 0.15s, text-decoration ease-in-out 0.15s, background-color ease-in-out 0.15s;
	&:visited {
		@extend a;
	}
	&:active,
	&:hover {
		text-decoration: underline;
	}
}
.breadcrumb {
	display: none;
	color: $font-color;
	text-transform: lowercase;
	font-size: 12px;
	line-height: 1.2em;
	padding-bottom: 0;
	margin-bottom: 1em;
	a {
		@extend .breadcrumb;
		margin-bottom: 0;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $secondary-color;
	font-weight: bold;
	&.node-title,
	&.node-title a {
		color: $primary-color;
	}
	a {
		color: $primary-color;
		&:hover {
			text-decoration: none;
		}
	}
}
h1 {
	font-size: 45px;
	line-height: 1.2em;
	color: $primary-color;
	font-weight: normal;
	&#page-title {}
}
h2 {
	font-size: 24px;
	line-height: 1.2em;
}
h3,
h4,
h5,
h6 {
	font-size: 16px;
	line-height: 1.2em;
}
/** start page.thtml **/
#page {
	> .row {
		position: relative;
		top: 0;
		left: 0;
	}
	#navbar {
		padding-bottom: 0;
		margin-bottom: 0;
		#name-and-slogan {
			margin-top: 16px;
			font-size: 24px;
			line-height: 1.2em;
			#site-slogan {
				font-size: 16px;
				line-height: 1.2em;
			}
		}
	}
}
footer {
	background-color: #CCCCCC;
	color: #FFFFFF;
	margin-top: 3em;
	.region-footer {
		padding: 2em 0;
	}
}
/** Menu **/
@import 'main_menu.scss';
/** NODES **/
/** general **/
.node {
	.field {
		&.field-type-image .field-item {
			vertical-align: top;
		}
	}
}
/** node page **/
.node-page {
	&.node-teaser {}
}
/** start Blocks **/
.block {
	> h2 {
		margin-bottom: 1.5em;
	}
}
aside {
	.block {
		margin-top: 1em;
	}
}
/** start forms **/
form {
	&#user-login {
		max-width: 400px;
	}
	.ajax-progress-throbber {
		display: none !important;
	}
}
/** Balance page **/
.page-balance-checker {
	h1#page-title {
		display: none;
	}
	#balance-checker-form {
		position: relative;
		width: 100%;
		max-width: 566px;
		margin-bottom: 51px;
		padding: 5px;
		border: 1px solid #d8d6d7;
		text-align: center;
		.form-item {
			margin: 0;
			padding: 0 7px 10px 0;
			display: inline-block;
			.sticky-placeholder-wrapper {
				input {
					width: 66px;
					height: 33px;
					border: 1px solid #d8d6d7;
					font-size: 15px !important;
					text-align: center;
					letter-spacing: 2px;
					font-weight: bold;
					&:focus {
						box-shadow: 0 1px 1px rgba(102, 102, 102, 0.075), 0 0 5px;
					}
				}
				.sticky-placeholder-label {
					padding: 5px !important;
					font-size: 13px !important;
					text-align: center;
					letter-spacing: 2px;
				}
			}
			.description {
				display: none;
			}
		}

		.captcha {
			@extend .clearfix;
			text-align: left;
			padding: 10px 0px 0px;

			img {
				max-width: 33%;
				height: auto;
			}

			.form-item {
				float: right;
				max-width: 66%;
				
				input {
					width: 184px;
					text-align: left;
				}

				.sticky-placeholder-wrapper {

					.sticky-placeholder-label {
						text-align: left;
						opacity: 0;
					}

				}

			}

		}

		#edit-submit {
			position: absolute;
			bottom: -51px;
			left: 0;
			height: 33px;
		}
	}
}
.ui-dialog {
	max-width: 90% !important;
}

#block-system-main {
	.form-item.form-type-textarea.form-item-field-reason {
		.form-textarea-wrapper.resizable.textarea-processed.resizable-textarea {
			width: 100%;
		}
	}
}

#block-system-main {
	iframe {
		max-width: 100%;
	}
}

// changes for ui-dialog to fit this in iframe height

.page-balance-checker {
	.ui-dialog.ui-front.drupal-status.drupal-status-status.ui-dialog-buttons{
		position: absolute;
		top: 0px !important;
		left: 50% !important;
		transform: translateX(-50%);
		min-width: 490px;
	}
}


/** Mistakes **/
.custom-dia {
	margin-top: 10px;
	text-align: center;
}
/** Should always be last **/
@import 'grid_custom.scss';
