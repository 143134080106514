/** 
	IMPORTANT: These styles ONLY apply for screen widths
	BELOW the first break-point 
	
	Media query is not needed inside this file
**/

// changes for ui-dialog to fit this in iframe height

.page-balance-checker {
	.ui-dialog.ui-front.drupal-status.drupal-status-status.ui-dialog-buttons{
		left: 0% !important;
		transform: translateX(0%);
		min-width: 100%;

		.ui-dialog-titlebar.ui-widget-header{
			font-size: 12px;
		}

		.ui-dialog-content.ui-widget-content{
			font-size: 12px;
			line-height: 1.3em;
		}

		.ui-dialog-buttonpane{
			margin-top: 5px;
		}
	}
}

.g-recaptcha{
	iframe {
		transform: scale(0.8);
		max-width: 100%;
		transform-origin: 0 0;
	}
}

.page-balance-checker > div:last-of-type{
		transform: scale(0.85);
		transform-origin: 0 0;
}

iframe[name~="challenge"]{
	transform: scale(0.85);
	transform-origin: 10px 10px;
}