/** 
	IMPORTANT: These styles apply for screen widths
	ABOVE and INCLUDING the second break-point 
	They are NOT LIMITED to a maximum width
	
	Media query is not needed inside this file
**/

.page-balance-checker {
	#balance-checker-form {
		padding: 20px 6px 10px 12px;
		.form-item {
			padding-bottom: 0px;
			.sticky-placeholder-wrapper {
				input {
					width: 96px;
					height: 43px;
					font-size: 15px !important;
				}
				.sticky-placeholder-label {
					padding: 10px 12px !important;
				}
			}	
		}

		.captcha {
			padding: 10px 9px 0px;

			.form-item {

				.sticky-placeholder-wrapper {

					input {
						width: 308px;
					}

					.sticky-placeholder-label {
						padding: 10px !important;
						opacity: 0.6;
					}

				}

			}

		}

	}
}