@import 'mixins.scss';
@import 'vars.scss';
@import 'grid.scss';

*, *:before, *:after {
	margin: 0px;
	padding: 0px;
	outline: none;
	background-repeat: no-repeat;
	@include box-sizing(border-box);
}

html {
	overflow-y: scroll;
}

body {
	min-height: 100%;
}

p, blockquote {
	margin: 0px 0px 1em;
	
	&:last-child, &.last-child {
		margin-bottom: 0px;	
	}
	
}

blockquote {
	font-size: 150%;
	line-height: 1.2em;
	font-style: italic;
}

.embed-container {
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
	height: 0px;
	overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

img {
	max-width: 100%;
	height: auto;
	border: 0px;
}

#toolbar a {
	font-weight: normal;
}

form {

	input, textarea {
		-webkit-appearance: none;
	}

	input[type="text"], input[type="tel"], input[type="password"], input[type="email"], input[type="integer"], input[type="number"], input[type="date"], input[type="datetime"], input[type="submit"], textarea {
		@include border-radius(0px);
		@include box-shadow(inset 0 1px 1px rgba($primary-color, .075));
		display: block;
		width: 100%;
		max-width: 100%;
		height: 34px;
		padding: 6px 12px;
		background-color: #FFFFFF;
		background-image: none;
		border: 1px solid $primary-color;
		-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	         -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
				
		&:focus {
			border-color: $secondary-color;
			outline: none;
			-webkit-box-shadow: inset 0 1px 1px rgba($secondary-color, .075), 0 0 8px rgba($secondary-color, .6);
				    box-shadow: inset 0 1px 1px rgba($secondary-color, .075), 0 0 8px rgba($secondary-color, .6);
		}

	}

	textarea {
		height: auto;
	}

	input {
		
	 	&[type="submit"] {
			@include border-radius(2px);
			width: auto;
			cursor: pointer;
			height: auto;
			border: 0px;
			line-height: 200%;
			color: #FFFFFF;
			background-color: $primary-color;
			display: inline-block;
			padding: 0px 10px;
		}
		
		&[type="checkbox"] {
			-webkit-appearance: checkbox;	
		}
		
		&[type="radio"] {
			-webkit-appearance: radio;	
		}
		
		&[type="file"]:focus, &[type="radio"]:focus, &[type="checkbox"]:focus {
			outline: thin dotted;
			outline: 5px auto -webkit-focus-ring-color;
			outline-offset: -2px;
		}
		
	}
	
	label, .webform-container-inline label {
		display: inline-block;
		width: auto;
		margin-right: 0.5em;
		vertical-align: top;
		font-weight: normal;
		color: #4f4f4f;
		
	}
	
	.form-type-radio label {
		width: auto;
	}
	
	.form-textarea-wrapper {
		display: inline-block;
		width: auto;
	}
	
	.form-item .description, .description.mollom-privacy, .form-item-mollom-captcha input[type="text"] {
		margin-left: 0px;
	}
	
	.form-item input.error, .form-item textarea.error, .form-item select.error {
		border: 1px solid #FF0000;
	}

	.webform-datepicker select, .webform-datepicker input[type="text"] {
		width: auto;
		max-width: none;
		min-width: 0px;
	}

	.icheckbox, .iradio {
		@include border-radius(2px);
		display: inline-block !important;
		width: 12px;
		height: 12px;
		border: 1px solid $primary-color;
		cursor: pointer;

		&.checked {
			background-color: $secondary-color;
		}

	}

	.iradio {
		@include border-radius(6px);
	}


}	

fieldset {
	min-width: 0px;
	padding: 0px;
	margin: 0px;
	border: 0px;

	legend {
		display: block;
		width: 100%;
		padding: 0;
		margin-bottom: 20px;
		font-size: 21px;
		line-height: inherit;
		color: #333;
		border: 0;
		border-bottom: 1px solid #e5e5e5;
	}

}

h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
	margin: 1em 0px 0px;
	
	&:first-child {
		margin-top: 0px;
	}
	
}

ul, ol {
	margin: 0.5em 0px 0.5em 1.5em;
}

div.spacer, span.spacer {
	height: 1px;
	width: 1px;
	overflow: hidden;
	visibility: hidden;	
}

table {
	
	thead {
		border: 0px;
	}
	
	tbody {
		border-top: 0px;
	}
	
	tr, tr.odd, tr.even {
		border: 0px;
		background-color: transparent;
		
		th, td {
			border: 0px;	
			vertical-align: top;
		}
		
	}

}

.contextual-links a {
	font-size: 12px !important;
	line-height: 1.1em !important;
	font-weight: normal !important;
	font-family: arial, sans-serif !important;
	text-transform: none !important;
	letter-spacing: 0px !important;
	text-align: left !important;
}

.clearfix:after {
	content: " ";
}

.f-left, .fleft {
	float: left !important;
}

.f-right, .fright {
	float: right !important;
}

.inline {
	display: inline;
}

.inline-block {
	display: inline-block;
}

.block {
	display: block;
}

.bold {
	font-weight: bold;
}

.hide {
	overflow: hidden;
}

.pointer {
	cursor: pointer;
}

.fullwidth {
	width: 100%;
}

.fullheight {
	height: 100%;
}

.fullsize {
	width: 100%;
	height: 100%;
}

.underline {
	text-decoration: underline !important;
}

.sticky-placeholder-wrapper {
	position: relative;
	display: inline-block;
	width: 100%;
	
	label {
		
		&.sticky-placeholder-label {
			cursor: text;
			position: absolute;
			top: 0px;
			left: 0px;
			font-weight: normal;
			margin-bottom: 0px;
			opacity: 0.6;
			filter: alpha(opacity=60);
			max-width: 100% !important;
		}
		
		&.sticky-placeholder-label-focus {
			opacity: 0.25;
			filter: alpha(opacity=25);
		}
		
	}
	
}

.sticky-placeholder-processed {
	display: none !important;
}

