@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
       -o-border-radius: $radius;
          border-radius: $radius;
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
      -ms-box-shadow: $shadow;
       -o-box-shadow: $shadow;
          box-shadow: $shadow;
}

@mixin box-sizing($box-sizing) {
  -webkit-box-sizing: $box-sizing;
     -moz-box-sizing: $box-sizing;
      -ms-box-sizing: $box-sizing;
       -o-box-sizing: $box-sizing;
          box-sizing: $box-sizing;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}