@media (max-width: ($min-width-sm - 1)) {

	@import 'media_xs_only.scss';
	
}

@media (min-width: $min-width-sm) {
	
	@import 'media_sm.scss';

}

@media (min-width: $min-width-sm) and (max-width: ($min-width-md - 1)) {

	@import 'media_sm_only.scss';
  
}

@media (min-width: $min-width-md) {
	
	@import 'media_md.scss';
	
}

@media (min-width: $min-width-md) and (max-width: ($min-width-lg - 1)) {
	
	@import 'media_md_only.scss';

}

@media (min-width: $min-width-lg) {
	
	@import 'media_lg.scss';
	
}

@media (min-width: $min-width-lg) and (max-width: ($min-width-xl - 1)) {

	@import 'media_lg_only.scss';
	
}

@media (min-width: $min-width-xl) {
	
	@import 'media_xl.scss';
	
}

@media (min-width: 550px) {
	
	@import 'media_550.scss';
	
}