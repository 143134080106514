$menu-border-radius: 2px;

#main-menu {
	
	.block-superfish {

		ul.sf-menu {

			li {
				margin: 0px;

				a {
					display: inline-block;
					font-size: 12px;
					line-height: 1.4em;
					padding: 0px 0.5em;
					text-decoration: none !important;
					color: #fff;
				}

				ul {
					background-color: $primary-color;
					padding: 5px 0px;

					li {

						a {
							line-height: 1.5em;
							width: 100%;

							&:hover {
							}

						}

						&.active-trail, &:hover, &.sfHover {

							> a {
								/*color: $primary-color;*/
								background-color: $secondary-color;								
							}

						}

						ul {
							top: -5px;
						}

					}

				}

				&.sf-depth-1 {
					border-left: 1px solid $primary-color;

					&:first-child {
						border-left: 0px;
					}

					> a {
						font-size: 14px;
						line-height: 1.6em;
						color: $font-color;
					}

					&.active-trail, &:hover, &.sfHover {
						
						> a {
							background-color: $primary-color;
							color: #fff;
						}

						> ul {
							top: (14px * 1.6);
							left: -1px;
						}

					}
					
				}

			}

		}

	}

	.nav-toggle-wrapper {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 32px;
		background-color: $primary-color;
		padding: 5px 0px;
		overflow: visible;
		z-index: 99;

		.nav-toggle-inner-wrapper {
			position: relative;
			top: 0px;
			left: 0px;

			.nav-toggle {
				text-transform: uppercase;
				font-size: 16px;
				line-height: 20px;
				display: inline-block;
				padding: 0px 20px 0px 6px;
				color: #FFF;
				float: right;
				background-image: url('../images/nav-toggle-bg.png');
				background-position: right center;

				&:hover, &.active {
					text-decoration: none;
				}
				
			}

			.region-navigation-xs {
				position: absolute;
				top: 27px;
				left: 0px;
				display: none;
				width: 100%;

				ul {
					background-color: $primary-color;
					padding: 0.5em 0px;
					margin: 0px;

					li {
						list-style: none;
						width: 100%;
						margin: 4px 0px;
						padding: 0px;
						line-height: 1.6em;

						a {
							display: block;
							color: #fff;
							padding: 0px 1em;

							&:hover {
								text-decoration: none;
							}

						}

						&.active-trail, &:hover, &.active {

							> a {
								/*color: $primary-color;*/
								background-color: $secondary-color;								
							}

						}

						ul {
							padding: 0px;

							li {

								a {
									padding: 0px 2em;
								}

								ul {

									li {

										a {
											padding: 0px 3em;
										}

										ul {

											li {

												a {
													padding: 0px 4em;
												}

												ul {

													li {

														a {
															padding: 0px 5em;
														}

													}

												}

											}

										}

									}

								}

							}

						}
					
					}

				}

			}

		}

	}
	
}

body.toolbar #main-menu .nav-toggle-wrapper {
	top: 30px;
}

body.toolbar-drawer #main-menu .nav-toggle-wrapper {
	top: 64px;
}