/** 
	IMPORTANT: These styles apply for screen widths
	ABOVE and INCLUDING the third break-point 
	They are NOT LIMITED to a maximum width
	
	Media query is not needed inside this file
**/

body.two-sidebars {

	#page {

		#main-content {

			position: absolute;
			top: 0px;
			left: $col-width-3;			

		}

	}

}

#navbar {

	#main-menu {
		margin-top: 2em;
	}
	
}